body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #333;
  --primary: #c2410c;
  --secondary: #08df9a;
  --gray: #ddd;
  --border-radius-max: 9999px;
  --border-radius-standard: 8px;
  --box-shadow-standard: 0 20px 25px -5px #ddd;
}

